/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSMTSUser = /* GraphQL */ `
  subscription OnCreateSMTSUser(
    $filter: ModelSubscriptionSMTSUserFilterInput
    $author: String
  ) {
    onCreateSMTSUser(filter: $filter, author: $author) {
      id
      author
      code
      lap {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSMTSUser = /* GraphQL */ `
  subscription OnUpdateSMTSUser(
    $filter: ModelSubscriptionSMTSUserFilterInput
    $author: String
  ) {
    onUpdateSMTSUser(filter: $filter, author: $author) {
      id
      author
      code
      lap {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSMTSUser = /* GraphQL */ `
  subscription OnDeleteSMTSUser(
    $filter: ModelSubscriptionSMTSUserFilterInput
    $author: String
  ) {
    onDeleteSMTSUser(filter: $filter, author: $author) {
      id
      author
      code
      lap {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSMTSLap = /* GraphQL */ `
  subscription OnCreateSMTSLap(
    $filter: ModelSubscriptionSMTSLapFilterInput
    $author: String
  ) {
    onCreateSMTSLap(filter: $filter, author: $author) {
      id
      author
      laptime
      lapCount
      lapsInRace
      fuelused
      car
      dataset
      user {
        id
        author
        code
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      sMTSUserLapId
      __typename
    }
  }
`;
export const onUpdateSMTSLap = /* GraphQL */ `
  subscription OnUpdateSMTSLap(
    $filter: ModelSubscriptionSMTSLapFilterInput
    $author: String
  ) {
    onUpdateSMTSLap(filter: $filter, author: $author) {
      id
      author
      laptime
      lapCount
      lapsInRace
      fuelused
      car
      dataset
      user {
        id
        author
        code
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      sMTSUserLapId
      __typename
    }
  }
`;
export const onDeleteSMTSLap = /* GraphQL */ `
  subscription OnDeleteSMTSLap(
    $filter: ModelSubscriptionSMTSLapFilterInput
    $author: String
  ) {
    onDeleteSMTSLap(filter: $filter, author: $author) {
      id
      author
      laptime
      lapCount
      lapsInRace
      fuelused
      car
      dataset
      user {
        id
        author
        code
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      sMTSUserLapId
      __typename
    }
  }
`;
