import React, { useState, useEffect } from "react";
import cars from "./cars.json";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import "./App.css";

TimeAgo.addDefaultLocale(en);
function Laphist({ laphistory, onClick }) {
  const timeAgo = new TimeAgo("en-ZA");
  const [lapHistoryState, setLapHistoryState] = useState([]);
  useEffect(() => {
    setLapHistoryState(
      laphistory.sort((a, b) => {
        let da = new Date(a.createdAt);
        let db = new Date(b.createdAt);
        return db - da;
      })
    );

    // eslint-disable-next-line
  }, [laphistory]);
  // console.log({ lapHistoryState });
  function formattime(time) {
    // console.log({ time });
    if (time > 0) {
      let mins = Math.trunc(time / 60000);
      let out = (mins + "").padStart(2, "0") + ":";
      let sec = "" + (time - mins * 60000) / 1000;
      sec = sec.split(".");
      sec[0] = sec[0].padStart(2, "0");
      sec[1] = sec[1].substring(0, 3).padEnd(3, "0");
      out += sec[0] + "." + sec[1];
      return out;
    } else return 0;
  }
  function carname(id) {
    let found = cars.find((z) => z.id === parseInt(id));
    if (found) return found.name;
    else return "car:" + id;
  }
  return (
    <div>
      {lapHistoryState.map((su, sui) => (
        <div id="laprecord" key={sui} onClick={() => onClick(su.dataset)}>
          {"🏁" +
            (su.lapCount + "").padStart(2, "0") +
            "  ⏱" +
            formattime(su.laptime) +
            " ⛽" +
            su.fuelused.toFixed(1) +
            "%" +
            " (" +
            timeAgo.format(su.createdAt) +
            ")" +
            " " +
            carname(su.car).substring(0,15)}
        </div>
      ))}
    </div>
  );
}

export default Laphist;
