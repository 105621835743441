import React, { useState, useEffect } from "react";
// ES modules
import {
  Amplify,
  Auth,
  Hub,
  API,
  graphqlOperation,
  Storage,
} from "aws-amplify";
import JSZip from "jszip";

import Button from "@mui/material/Button";
import Laphist from "./laphistory";
// import Chart from "./chartbox";
import Chart2 from "./chartbox2";
import Chart3 from "./chartbox3";
import awsconfig from "./aws-exports";
import * as queries from "./graphql/queries";
// import * as mutations from "./graphql/mutations";
import * as subscriptions from "./graphql/subscriptions";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
awsconfig.oauth.domain = "auth.singlemaketunerseries.com";
awsconfig.oauth.scope = [
  "phone",
  "email",
  "openid",
  "profile",
  "aws.cognito.signin.user.admin",
];
awsconfig.oauth.responseType = "code";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  awsconfig.oauth.redirectSignIn = "http://localhost:3000/";
  awsconfig.oauth.redirectSignOut = "http://localhost:3000/";
} else {
  awsconfig.oauth.redirectSignIn =
    "https://overdrive.singlemaketunerseries.com/";
  awsconfig.oauth.redirectSignOut =
    "https://overdrive.singlemaketunerseries.com/";
}
awsconfig.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);
  const [lapHistory, setLapHistory] = useState([]);
  const [lapGraph, setLapGraph] = useState([]);
  const [ismk, setismk] = useState(false);
  const [useremail, setuseremail] = useState("");

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          Auth.currentAuthenticatedUser().then((z) => {
            setUser(z);
          });
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
    Auth.currentAuthenticatedUser()
      .then((z) => {
        setUser(z);
      })
      .catch((z) => {});
  }, []);

  async function checkorcreatenewuser(id) {
    try {
      let userddb = await API.graphql(
        graphqlOperation(queries.getSMTSUser, { id })
      );
      let laps = userddb.data.getSMTSUser.lap.items;
      laps.forEach((item, i) => {
        item.createdAt = new Date(item.createdAt);
      });
      setLapHistory(laps.filter((z) => z.laptime > 0));
    } catch (error) {
      console.error({ error });
    }
  }

  async function loadallusers() {
    try {
      let userddb = await API.graphql(graphqlOperation(queries.listSMTSUsers));
      console.log({ userddb });
      // API.graphql(graphqlOperation(subscriptions.onCreateSMTSLap)).subscribe({
      //   next: (todoData) => {
      //     let temp = todoData.value.data.onCreateSMTSLap;
      //     temp.createdAt = new Date(temp.createdAt);
      //     if (temp.laptime > 0) setLapHistory((old) => [...old, temp]);
      //   },
      // });
      // let laps = userddb.data.getSMTSUser.lap.items;
      // laps.forEach((item, i) => {
      //   item.createdAt = new Date(item.createdAt);
      // });
      // setLapHistory(laps.filter((z) => z.laptime > 0));
    } catch (error) {
      console.error({ error });
    }
  }

  useEffect(() => {
    API.graphql(graphqlOperation(subscriptions.onCreateSMTSLap)).subscribe({
      next: (todoData) => {
        let temp = todoData.value.data.onCreateSMTSLap;
        temp.createdAt = new Date(temp.createdAt);
        if (temp.laptime > 0) setLapHistory((old) => [...old, temp]);
      },
    });
  }, []);

  useEffect(() => {
    if (user !== null) {
      setuseremail(user.attributes.email);
      if (user.attributes.email === "mithunkalan@gmail.com") setismk(true);
      else setismk(false);
      if (ismk) loadallusers();
      // } else {
      checkorcreatenewuser(user.username);
      // }
      // if (useremail) console.log({ useremail });
    } else {
      setismk(false);
      setuseremail("");
    }
    // eslint-disable-next-line
  }, [user]);

  async function getLapzip(id) {
    let f1 = await Storage.get(id, { level: "private" });
    let f2 = await fetch(f1);
    let f3 = await f2.blob();
    let f4 = await JSZip.loadAsync(f3);
    let f4a = Object.keys(f4.files);
    let f5 = await f4.files[f4a].async("string");
    f5 = f5.split("\n").map((z) => z.split(","));
    let f6 = [];
    let head = f5.shift();
    f5.forEach((q) => {
      let item = {
        x: parseFloat(q[0]),
        y: parseFloat(q[1]),
        rpm: parseFloat(q[2]),
        fuel: parseFloat(q[3]),
        mps: parseFloat(q[4]),
        FLtemp: parseFloat(q[5]),
        FRtemp: parseFloat(q[6]),
        RLtemp: parseFloat(q[7]),
        RRtemp: parseFloat(q[8]),
        lap: parseInt(head[4]),
        lapsInRace: parseInt(head[3]),
        packetid: parseInt(q[9]),
        lastLapTime: parseInt(q[10]),
        tod: parseInt(q[11]),
        qualipos: parseInt(head[5]),
        carsinrace: parseInt(q[12]),
        flags: parseInt(q[13]),
        gear: parseInt(q[14]),
        suggestedGear: parseInt(q[15]),
        throttle: parseInt(q[16]),
        brake: parseInt(q[17]),
        carCode: parseInt(head[2]),
      };
      f6.push(item);
    });
    return f6;
  }

  return (
    <div style={{ padding: 10, fontFamily: "courier new", fontSize: 12 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=com.ysafu&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style={{ height: 50 }}
          />
        </a>
        <img src="/logo5alpha.png" style={{ height: 50 }} alt="" />
        <div>
          {user ? (
            <Button variant="contained" onClick={() => Auth.signOut()}>
              Logout of {useremail}
            </Button>
          ) : (
            <Button variant="contained" onClick={() => Auth.federatedSignIn()}>
              Login
            </Button>
          )}
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "30%", height: "100vh" }}>
          <Laphist
            laphistory={lapHistory}
            onClick={async (e) => {
              let temp = Object.assign([], lapGraph);
              let newdata = await getLapzip(e);
              temp.push(newdata);
              if (temp.length > 2) temp.shift();
              // console.log(temp);
              setLapGraph(temp);
            }}
          />
        </div>
        <div style={{ width: "70%", display: "flex", flexDirection: "column" }}>
          <div style={{ width: "98%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart2 data={lapGraph} type={"throttle"} />
            </div>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart2 data={lapGraph} type={"brake"} />
            </div>
          </div>

          <div style={{ width: "98%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"rpm"} />
            </div>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"gear"} />
            </div>
          </div>

          <div style={{ width: "98%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"mps"} />
            </div>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"fuel"} />
            </div>
          </div>

          <div style={{ width: "98%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"FLtemp"} />
            </div>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"FRtemp"} />
            </div>
          </div>

          <div style={{ width: "98%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"RLtemp"} />
            </div>
            <div
              style={{
                width: "50%",
                height: "300px",
                borderColor: "#666",
                borderWidth: 2,
                borderStyle: "solid",
                padding: 5,
                margin: 5,
              }}
            >
              <Chart3 data={lapGraph} type={"RRtemp"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
