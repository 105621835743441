/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://cz3evzjwqzdmligzx6lweutanq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-west-1:6446a26c-8efb-4e56-b657-12a254dce024",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_syj1Y8UpB",
    "aws_user_pools_web_client_id": "231278j7dq773vvp3l6eadp5m6",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "smtsstoragebucket160130-production",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
