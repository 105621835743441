/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSMTSUser = /* GraphQL */ `
  query GetSMTSUser($id: ID!) {
    getSMTSUser(id: $id) {
      id
      author
      code
      lap {
        items {
          id
          author
          laptime
          lapCount
          lapsInRace
          fuelused
          car
          dataset
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSMTSUsers = /* GraphQL */ `
  query ListSMTSUsers(
    $filter: ModelSMTSUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMTSUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        code
        createdAt
        updatedAt
        lap {
          items {
            id
            author
            laptime
            lapCount
            lapsInRace
            fuelused
            car
            dataset
            createdAt
            updatedAt
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMTSLap = /* GraphQL */ `
  query GetSMTSLap($id: ID!) {
    getSMTSLap(id: $id) {
      id
      author
      laptime
      lapCount
      lapsInRace
      fuelused
      car
      dataset
      user {
        id
        author
        code
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      sMTSUserLapId
      __typename
    }
  }
`;
export const listSMTSLaps = /* GraphQL */ `
  query ListSMTSLaps(
    $filter: ModelSMTSLapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMTSLaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        laptime
        lapCount
        lapsInRace
        fuelused
        car
        dataset
        createdAt
        updatedAt
        sMTSUserLapId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
