import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,

  maintainAspectRatio: false,
  elements: {
    point: {
      pointBorderWidth: 0,
      pointRadius: 0,
    },
    line: {
      borderWidth: 1,
      spanGaps: true,
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "throttle",
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
};

const colours = {
  r1: "#EF9A9A",
  r2: "#F44336",
  r3: "#B71C1C",
  g1: "#81C784",
  g2: "#43A047",
  g3: "#1B5E20",
  b1: "#7986CB",
  b2: "#3949AB",
  b3: "#1A237E",
};

export default function App({ data, type }) {
  const [newdata, setnewdata] = useState({
    labels: [],
    datasets: [{ label: "", data: [] }],
  });
  useEffect(() => {
    if (data.length > 0) {
      let compact = [];
      data.forEach((z) => {
        z.forEach((x) => {
          let ky = type + "-" + x.lap;
          let found = compact.find(
            (q) => q.tod === Math.round(x.tod / 33) * 33
          );
          if (found) {
            found[ky] = Math.round(x[type]);
          } else {
            let item = { tod: Math.round(x.tod / 33) * 33 };
            item[ky] = Math.round(x[type]);
            compact.push(item);
          }
        });
      });
      compact = compact.sort((a, b) => a.tod - b.tod);

      let nd = {
        labels: compact.map((z) => z.tod),
        datasets: Object.keys(compact[0])
          .slice(1, 3)
          .map((z, i) => {
            return {
              label: z,
              data: compact.map((a) => a[z]),
              borderColor: i === 1 ? colours.r1 : colours.b3,
              backgroundColor: i === 1 ? colours.r1 : colours.b3,
            };
          }),
      };

      setnewdata(nd);
    }
  }, [data]);

  return <Line options={options} data={newdata} />;
}
